import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import closeIcon from '../assets/icons/close_icon.png'
import logo from '../assets/logos/logo_horizontal.png'
import frank from '../assets/artboard/frank_1.png'


const ContainerDiv = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 45vw;
    height: 70%;
    background: #fff;
    z-index: 100;
    border: 1px solid black;
    padding: 5px;

    @media screen and (max-width:700px){
        width: 75vw;
    }
`
const Header = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    background: linear-gradient(90deg, rgba(10, 57, 221, 0.86) 0.7%, #CCCCCC 99.26%);
    padding: 5px 0 5px 25px;
    pointer-events: all;
    
    span{
        pointer-events: none;
        color: black;
        font-size: 1.2rem;
        color: whitesmoke;
    }
`

const SubHeader = styled.div`
    position: relative;
    width: 100%;
    height: 150px;
    margin-top: 10px;

    span{
        position: absolute;
        top: 10px;
        right: 10px;
        font-weight: bolder;
    }

    img{
        max-height: 90%;
        width: 80%;
    }
`

const Content = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    &::after{
        content: '';
        position: absolute;
        top: -20px;
        background: linear-gradient(90deg, #0B1FD7 -0.05%, #B0B8FF 51.53%, #0B1FD7 99.98%);
        width: 100%;
        height: 20px;
    }
    img{
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        width: 50%;
    }
    a{
        position: absolute;
        text-decoration: none;
        color: black;
        bottom: 70px;
        left: 100px;
        font-size: large;
        border: solid #c8c8c8 1.5px;
        border-color: #c8c8c8 #686868 #686868 #c8c8c8;
        box-shadow: 1px 1px 1px #686868;
        cursor: pointer;
        pointer-events: all;
        padding: 15px;
    }
    @media screen and (max-width:700px){
        img{
            width: 70%;
        }

    a{
        bottom: 40px;
        left: 50px;
        font-size: normal;
        padding: 5px;
    }
    }
`

const CSRHeading = styled.h2`
    margin: 10px auto 5px 40px;
    font-size: x-large;
`

const CSRFooter = styled.span`
    position: absolute;
    bottom: 5px;
    left: 10px;
    font-size: x-small;
    width: 50%;
    
    @media screen and (max-width:700px){
        width: 80%;
    }
    
`

const Speech = styled.div`
    position: absolute;
    top: 100px;
    left: 25%;
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #D9D9D9;

    span{
        z-index: 1;
    }

    &::before{
        content: '';
        position: absolute;
        right: -15px;
        top: 20px;
        width: 30px;
        height: 30px;
        transform: rotate(45deg);
        background: #D9D9D9;
        z-index: 0;
    }

    @media screen and (max-width:700px){
        top: 100px;
        left: 5px;
        padding: 10px;
        &::before{
            content: '';
            position: absolute;
            right: -5px;
            top: 5px;
            width: 10px;
            height: 10px;
        }
    }
`

const NotFoundPage = () => {
 
    const [moveWindow, toggleWindow] = useState()
    const [offset,updateOffset] = useState({x:0,y:0})

    // const [windowPos, setWindow] = useState({x:0,y:0})
    const mainWindowRef = useRef()
    
    //offset works
    const onClickOffset = (e)=>{
        const mainWindow = mainWindowRef.current
        var x= -(mainWindow.getBoundingClientRect().x - e.clientX)
        var y = -(mainWindow.getBoundingClientRect().y - e.clientY)
        updateOffset({x,y})
    }

    var handleWindowMove = (e)=>{
        const mainWindow = mainWindowRef.current
        var newPosX = e.clientX - offset.x
        var newPosY = e.clientY - offset.y
        
        if(moveWindow===true){
            mainWindow.style.transform = `translate3d(${newPosX}px,${newPosY}px,0)`
        }
    }

    useEffect(()=>{
        window.addEventListener('mousemove',handleWindowMove)
        return ()=> window.removeEventListener('mousemove',handleWindowMove)
    },[moveWindow])

    useEffect(()=>{
        const mainWindow = mainWindowRef.current
        mainWindow.style.transform = `translate3d(10%,10vh,0)`
        document.body.style.background = 'black'
    },[])

    return (
        <ContainerDiv ref={mainWindowRef}>
            <Header onPointerDown={(e)=>{toggleWindow(true); onClickOffset(e);}} onPointerUp={()=>{toggleWindow(false);}}>
                <span>404: Not Found</span>
            </Header>
            <SubHeader>
                <img src={logo}/>
                <span>Windows</span>
            </SubHeader>
            <Content>
                    <CSRHeading>404: Not Found</CSRHeading>
                    <CSRFooter>B4RTO.COM is a fun project I decided to make to share more about myself. You could say it is my portfolio!</CSRFooter>
                    <img src={frank} alt='Franklin Kipkalis Barto'/>
                    <Speech>
                        <span>Oops, it seems this <br/>route doesn't exist!</span>
                    </Speech>
                    <a href="https://franklinbarto.com/">Back Home</a>
            </Content>
        </ContainerDiv>
    )
}

export default NotFoundPage

export const Head = () => {
    return(
        <>
            <title>Page Not Found - Franklin Barto's Portfolio</title>
            <meta name="description" content="Oops! The page you are looking for is not found. Please navigate back to the homepage or contact Franklin Barto for assistance."/>
            <meta name="robots" content="noindex, nofollow"/>
        </>
    )
}
